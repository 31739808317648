<template>
  <CDropdown aligment="start" placement="top-end" auto-close="outside">
    <CDropdownToggle custom>
      <CIcon
        :icon="cilBell"
        size="xl"
        class="tw-cursor-pointer !tw-text-gray-500"
        @click="handleDropdownToggleClick"
      />
    </CDropdownToggle>
    <CBadge
      v-if="unreadNotifications && unreadNotifications.length > 0"
      class="tw-bg-primary !tw-top-6 tw-select-none"
      position="top-end"
      shape="rounded-circle"
    >
      <span>{{ unreadNotifications.length }}</span>
    </CBadge>
    <CDropdownMenu
      class="tw-shadow-xl !tw-w-[70vw] sm:!tw-w-96 !tw-py-0 !tw-mt-4 tw-overflow-y-auto tw-max-h-96"
    >
      <div class="tw-px-5 tw-bg-gray-100 tw-py-2">
        <div class="tw-flex tw-justify-between tw-items-center">
          <div>
            <span
              class="tw-font-bold tw-text-gray-500 tw-mr-2 tw-align-middle"
              >{{ $t('notifications.title.notifications') }}</span
            >
          </div>
          <CLoadingButton
            v-if="hasNotificationData"
            class="!tw-text-purple-500 !tw-border-none"
            :disabled="!hasNotificationData || clearAllMutation.isPending.value"
            :loading="clearAllMutation.isPending.value"
            @click="clearAllMutation.mutate"
          >
            {{ $t('notifications.button.clearAll') }}
          </CLoadingButton>
        </div>
      </div>
      <div v-if="notificationData">
        <CDropdownItem
          v-for="notification in notificationData"
          :key="notification.notificationId"
          as="div"
          class="!tw-px-7 !tw-py-4"
        >
          <div class="tw-flex tw-justify-between tw-items-center">
            <div class="tw-align-middle">
              <span
                v-if="!notification.isReaded"
                class="tw-h-2 tw-w-2 tw-bg-primary tw-inline-block tw-rounded-full tw-mr-1"
              ></span>
              <span class="tw-text-sm tw-mr-1 tw-select-none tw-uppercase">{{
                $t(notification.title)
              }}</span>
            </div>
            <CSpinner
              v-if="
                clearMutation.variables.value === notification.notificationId &&
                clearMutation.isPending.value
              "
              class="!tw-align-middle"
              size="sm"
            />
            <CIcon
              v-else
              class="tw-cursor-pointer"
              :icon="cilX"
              @click="clearMutation.mutate(notification.notificationId)"
            />
          </div>
          <span class="tw-block tw-mb-2 tw-text-xs tw-text-gray-500">{{
            $t(notification.content)
          }}</span>
          <span class="tw-text-sm tw-text-gray-700 tw-capitalize">{{
            getTimeAgoTranslationByDate(notification.date, $t)
          }}</span>
        </CDropdownItem>
      </div>
      <p class="tw-text-center tw-font-bold tw-pt-4">
        {{ $t('notifications.text.noMoreNotifications') }}
      </p>
    </CDropdownMenu>
  </CDropdown>
</template>

<script lang="ts" setup>
import { cilBell, cilX } from '@coreui/icons'
import NotificationService from '~/services/MSAPI/notification'
import type { NGetNotification } from '~/services/MSAPI/notification/notification/types'

const notificationService = new NotificationService()
const toastStore = useToastStore()
const { $errorCodeHandler } = useNuxtApp()

const notificationsLimit = ref(10)
const hasMoreNotifications = ref(false)
const notificationData = reactive([]) as NGetNotification.IResponseData[]

const handleNotificationListQuery = async () => {
  const result = await notificationService.notification.getNotification(
    notificationsLimit.value,
    0,
  )

  hasMoreNotifications.value = result.data.length === notificationsLimit.value
  notificationData.splice(0, notificationData.length, ...result.data)

  return result.data || []
}

const hasNotificationData = computed(() => notificationData.length > 0)

const { refetch: refetchNotificationList, data: notificationList } = useQuery({
  queryKey: ['notificationList'],
  queryFn: () => handleNotificationListQuery(),
  enabled: () => !hasNotificationData.value,
  refetchInterval: 5000,
})

const handleNotificationNewListQuery = async () => {
  if (!notificationList?.value?.[0]) return []

  const result =
    await notificationService.notification.getNewNotificationFromLast(
      notificationList.value[0].notificationId as string,
    )

  hasMoreNotifications.value = result.data.length === notificationsLimit.value

  const isNotificationNewAlreadyPresent = result.data.some((item) => {
    return notificationData.some(
      (existingItem) => existingItem.notificationId === item.notificationId,
    )
  })

  if (!isNotificationNewAlreadyPresent) {
    notificationData.unshift(...result.data)
  }

  return result.data || []
}

useQuery({
  queryKey: ['notificationNewList'],
  queryFn: handleNotificationNewListQuery,
  refetchInterval: 5000,
  enabled: () => hasNotificationData.value,
})

const clearAllMutation = useMutation({
  mutationFn: () => notificationService.notification.clearAllNotifications(),
  onError: (error: any) => {
    toastStore.createToast({
      content: $errorCodeHandler(error),
      type: EToastType.DANGER,
    })
  },
  onSuccess() {
    refetchNotificationList()
  },
})

const clearMutation = useMutation({
  mutationFn: async (notificationId: string) => {
    await notificationService.notification.clearNotification(notificationId)
    await refetchNotificationList()
  },
  onError: (error: any) => {
    toastStore.createToast({
      content: $errorCodeHandler(error),
      type: EToastType.DANGER,
    })
  },
})

const unreadNotifications = computed(() => {
  return notificationData.filter((notification) => !notification.isReaded)
})

const unreadNotificationsIds = computed(() =>
  unreadNotifications.value.map(
    (unreadNotification) => unreadNotification.notificationId,
  ),
)

const markAsReadMutation = useMutation({
  mutationFn: () =>
    notificationService.notification.markAsReadNotifications(
      unreadNotificationsIds.value,
    ),
  onSuccess() {
    refetchNotificationList()
  },
})

const isDropdownOpen = ref(false)

const handleDropdownToggleClick = () => {
  isDropdownOpen.value = !isDropdownOpen.value

  if (!isDropdownOpen.value && unreadNotificationsIds.value.length > 0) {
    markAsReadMutation.mutate()
  }
}
</script>
