<script setup lang="ts">
import VueMultiselect from 'vue-multiselect'
import type { EPlanType } from '~/constants/organizationPlan'
import BalanceService from '~/services/MSAPI/balance'
import UserService from '~/services/MSAPI/user'
import type { NGetOrganizationsByUserId } from '~/services/MSAPI/user/user/types'

interface ISelectOption {
  id: string
  name: string
}

const route = useRoute()

const userService = new UserService()
const balanceService = new BalanceService()
const authStore = useAuthStore()

const organizationListQueryParams = {
  includeDescendentOrganizations: true,
  page: 1,
  count: 99999,
} as NGetOrganizationsByUserId.IParams

const { data: organizationListData, isFetching: isFetchingOrganizationData } =
  useQuery({
    queryKey: ['organizationListHeader'],
    queryFn: () =>
      userService.user.getOrganizationsByUserId(
        authStore.userData?.userId as string,
        organizationListQueryParams,
      ),
    enabled: () =>
      Boolean(route.params.organizationId) &&
      Boolean(authStore.userData?.userId),
  })

const organizationOptions = computed(() => {
  const mappedOptions = organizationListData.value?.data?.items.map(
    ({ organizationId, companyName }: NGetOrganizationsByUserId.IItem) => {
      return {
        id: organizationId,
        name: companyName,
      }
    },
  )

  return mappedOptions || []
})

const organizationIdRouteAsOption = computed(() => {
  const organizationId = route.params.organizationId
  const matchedOrganization = organizationOptions.value.find(
    (option) => option.id === organizationId,
  )
  return {
    id: organizationId,
    name: matchedOrganization?.name,
  }
})

const isDisabled = computed(
  () =>
    isFetchingOrganizationData.value || organizationOptions.value.length === 0,
)

const selected = ref(organizationIdRouteAsOption)

const selectOrganization = async (value: ISelectOption) => {
  try {
    const session = await userService.user.getSession(value.id)
    const planRequest = await balanceService.organizationPlan.get(value.id)

    authStore.setUserSession(session.data.user)
    authStore.setOrganizationSession(session.data.organization)
    authStore.setOrganizationPlanSession(
      planRequest.data.plan?.name as string,
      planRequest.data.plan?.planType as EPlanType,
    )
  } catch (e) {
    console.error('error', e)
  }

  await navigateTo({
    /* name: route.name || 'organizationId-dashboard', */
    name: 'organizationId-dashboard', // Por definição, ao selecionar uma organização deve ser direcionado para o dashboard
    params: { organizationId: value.id },
  })
}
</script>

<template>
  <div>
    <span
      class="tw-block tw-uppercase tw-text-sm tw-font-medium tw-text-gray-400 tw-mb-1"
      >{{
        $t('organization.subOrganization.subOrganizationList.organization')
      }}</span
    >
    <VueMultiselect
      v-model="selected"
      :options="organizationOptions"
      :clear-on-select="false"
      :show-labels="false"
      :allow-empty="false"
      :loading="isFetchingOrganizationData"
      :disabled="isDisabled"
      :show-no-options="false"
      close-on-select
      :placeholder="$t('organization.dropdown.select.placeholder')"
      label="name"
      track-by="id"
      @select="selectOrganization"
    />
  </div>
</template>
