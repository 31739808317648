<script lang="ts" setup>
import { cibFacebook, cibLinkedinIn } from '@coreui/icons'

const languageStore = useLanguageStore()

const currentYear = computed(() => {
  return new Date().getFullYear()
})

/* TODO: Adicionar route names */
const navMenu = reactive([
  {
    translationKey: 'general.footer.plataform',
    childItems: [
      {
        routeName: '',
        translationKey: 'general.header.dropdownItems.audience',
      },
      {
        routeName: '',
        translationKey:
          'sender.domainSender.domainSenderList.status.campaignList',
      },
      {
        routeName: '',
        translationKey: 'general.header.navs.emailTemplate',
      },
    ],
  },
  {
    translationKey: 'general.footer.company',
    childItems: [
      {
        routeName: '',
        translationKey: 'general.header.dropdownItems.plataform',
      },
      {
        routeName: '',
        translationKey: 'general.header.dropdownItems.abountUs',
      },
      {
        routeName: '',
        translationKey: 'general.header.navs.help',
      },
    ],
  },
])
</script>
<template>
  <footer
    class="tw-pt-16 tw-px-8 tw-bg-purple-50 tw-text-gray-500 tw-flex tw-flex-col xl:tw-h-80 xl:tw-px-4"
  >
    <div
      class="tw-flex tw-flex-col tw-justify-between tw-m-auto tw-max-w-[1300px] tw-w-full xl:tw-flex-row"
    >
      <div>
        <NuxtImg
          class="tw-mb-6"
          src="img/logo-color.svg"
          width="148px"
          height="52px"
        />
        <p>{{ $t('general.footer.text-description') }}</p>
      </div>
      <!-- TODO: Exibir div abaixo apenas se não estiver na tela de seleção da ORG -->
      <div v-for="navItem in navMenu" :key="navItem.translationKey">
        <span class="tw-block tw-uppercase tw-mb-4 tw-text-gray-800">{{
          $t(navItem.translationKey)
        }}</span>
        <ul class="tw-pl-0">
          <li
            v-for="childItem in navItem.childItems"
            :key="childItem.translationKey"
            class="tw-mb-2"
          >
            <NuxtLink
              class="tw-no-underline tw-text-inherit tw-cursor-pointer hover:tw-text-gray-700"
            >
              {{ $t(childItem.translationKey) }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div>
        <span class="tw-block tw-uppercase tw-mb-4 tw-text-gray-800">
          {{ $t('general.footer.choose-language') }}
        </span>

        <!-- TODO: Funcionalidade de troca de idioma -->
        <ul class="tw-pl-0 mt-3">
          <li class="list-inline-item mx-2">
            <span
              class="tw-uppercase tw-cursor-pointer hover:tw-text-gray-700 tw-text-sm p-2 tw-w-9 tw-h-9 tw-text-center tw-block tw-p-0 tw-rounded-full tw-leading-5"
              :class="{
                'tw-bg-purple-100':
                  languageStore.userLanguage ==
                  SUPPORTED_LANGUAGE_OPTIONS.English,
              }"
              @click="
                languageStore.setUserLanguage(
                  SUPPORTED_LANGUAGE_OPTIONS.English,
                )
              "
              >{{ $t('general.footer.english') }}</span
            >
          </li>
          <li class="list-inline-item mx-2">
            <span
              class="tw-uppercase tw-cursor-pointer hover:tw-text-gray-700 tw-text-sm p-2 tw-w-8 tw-h-8 tw-text-center tw-block tw-p-0 tw-rounded-full tw-leading-5"
              :class="{
                'tw-bg-purple-100':
                  languageStore.userLanguage ==
                  SUPPORTED_LANGUAGE_OPTIONS.Portuguese,
              }"
              @click="
                languageStore.setUserLanguage(
                  SUPPORTED_LANGUAGE_OPTIONS.Portuguese,
                )
              "
              >{{ $t('general.footer.portuguese') }}</span
            >
          </li>
        </ul>
      </div>
      <div>
        <span class="tw-block tw-uppercase tw-mb-4 tw-text-gray-800">
          {{ $t('general.footer.connect-with-us') }}
        </span>

        <ul class="tw-pl-0 mt-3">
          <li class="list-inline-item mx-2">
            <NuxtLink
              class="tw-no-underline tw-text-inherit tw-cursor-pointer hover:tw-text-gray-700"
              to="https://www.facebook.com/SenditPortugal/"
              target="_blank"
            >
              <CIcon size="lg" :icon="cibFacebook" />
            </NuxtLink>
          </li>
          <li class="list-inline-item mx-2">
            <NuxtLink
              class="tw-no-underline tw-text-inherit tw-cursor-pointer hover:tw-text-gray-700"
              to="https://www.linkedin.com/company/senditdigitalcommunication/"
              target="_blank"
            >
              <CIcon size="lg" :icon="cibLinkedinIn" />
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-justify-between tw-m-auto tw-max-w-[1300px] tw-w-full xl:tw-flex-row">
      <p class="tw-text-center text-md-center tw-mb-0">
        <NuxtLink
          class="tw-no-underline tw-text-inherit tw-cursor-pointer hover:tw-text-gray-700"
          target="_blank"
          to="https://sendit.pt"
        >
          Sendit © {{ currentYear }} {{ $t('general.footer.copyright') }}
        </NuxtLink>
      </p>
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-1 xl:justify-content-end">
        <!-- TODO: Adicionar Links -->
        <NuxtLink
          class="tw-no-underline tw-text-inherit tw-cursor-pointer hover:tw-text-gray-700"
        >
          {{ $t('general.footer.privacy-policy') }}
        </NuxtLink>
        |
        <NuxtLink
          class="tw-no-underline tw-text-inherit tw-cursor-pointer hover:tw-text-gray-700"
        >
          {{ $t('general.footer.terms-conditions') }}
        </NuxtLink>
      </div>
    </div>
  </footer>
</template>
