<template>
  <CSidebar
    :visible="isVisible"
    position="fixed"
    @visible-change="(value: boolean) => emits('sidebarVisibleChanged', value)"
  >
    <div class="tw-w-[inherit] tw-border-r tw-border-r-purple-200 tw-flex-grow">
      <CSidebarHeader>
        <div class="tw-w-48 tw-flex-auto">
          <OrganizationDropdown />
        </div>
      </CSidebarHeader>
      <CSidebarNav>
        <div class="tw-overflow-y-auto">
          <div
            v-for="navigationItem in navigationItems"
            :key="navigationItem.routeName"
          >
            <CNavGroup
              v-if="navigationItem.childItems"
              :visible="isAnyChildRouteActive(navigationItem.childItems)"
            >
              <template #togglerContent>
                <CIcon
                  custom-class-name="nav-icon"
                  :icon="navigationItem.icon"
                />
                <span class="tw-uppercase tw-font-semibold">{{
                  $t(navigationItem.translationKey)
                }}</span>
              </template>
              <CNavItem
                v-for="navigationItemChild in navigationItem.childItems"
                :key="navigationItemChild.routeName"
                href="javascript:void(0);"
                :active="isRouteActive(navigationItemChild.routeName)"
                @click="navigateTo({ name: navigationItemChild.routeName })"
              >
                <CIcon custom-class-name="nav-icon" icon="nav-icon-bullet" />
                {{ $t(navigationItemChild.translationKey) }}
              </CNavItem>
            </CNavGroup>
            <CNavItem
              v-else
              href="javascript:void(0);"
              :active="isRouteActive(navigationItem.routeName)"
              @click="navigateTo({ name: navigationItem.routeName })"
            >
              <CIcon custom-class-name="nav-icon" :icon="navigationItem.icon" />
              {{ $t(navigationItem.translationKey) }}
            </CNavItem>
          </div>
        </div>
      </CSidebarNav>
      <CSidebarFooter class="border-top tw-sticky">
        <CSidebarToggler @click="emits('sidebarTogglerClicked')" />
      </CSidebarFooter>
    </div>
  </CSidebar>
</template>

<script lang="ts" setup>
import {
  cilAvTimer,
  cilBullhorn,
  cilClone,
  cilCode,
  cilContact,
  cilViewQuilt,
  cilBuilding,
} from '@coreui/icons'

interface IProps {
  isVisible: boolean
}

interface INavigationItem {
  translationKey: string
  routeName?: string
  icon?: string[]
  childItems?: INavigationItem[]
}

defineProps<IProps>()

const route = useRoute()

const emits = defineEmits<{
  (e: 'sidebarTogglerClicked'): void
  (e: 'sidebarVisibleChanged', value: boolean): void
}>()

const isRouteActive = (routeName?: string) => {
  return routeName === route.name
}

const isAnyChildRouteActive = (childItems: typeof navigationItems) => {
  return childItems.some((item) => isRouteActive(item.routeName))
}

const navigationItems = reactive<INavigationItem[]>([
  {
    routeName: 'organizationId-dashboard',
    translationKey: 'general.header.navs.dashboard',
    icon: cilViewQuilt,
  },
  {
    translationKey: 'general.header.navs.audience',
    icon: cilContact,
    childItems: [
      {
        routeName: '', // Definir
        translationKey: 'general.header.dropdownItems.audience',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.dropdownItems.createAudience',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.dropdownItems.segmentation',
      },
    ],
  },
  {
    translationKey: 'general.header.navs.campaign',
    icon: cilBullhorn,
    childItems: [
      {
        translationKey: 'campaign.create.quick-sms',
        routeName: 'organizationId-campaign-quick-sms',
      },
      {
        routeName: '', // Definir
        translationKey:
          'sender.domainSender.domainSenderList.status.campaignList',
      },
    ],
  },
  {
    translationKey: 'general.header.navs.automation.navTitle',
    icon: cilAvTimer,
    childItems: [
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.automation.createNew',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.automation.list',
      },
    ],
  },
  {
    translationKey: 'general.header.navs.api',
    icon: cilCode,
    childItems: [
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.transational-documentation',
      },
      {
        routeName: 'organizationId-api-transactions', // Definir
        translationKey: 'general.header.navs.transational-transational-log',
      },
      {
        routeName: '', // Definir
        translationKey: 'general.header.navs.transational-apiKey',
      },
    ],
  },
  {
    translationKey: 'general.header.navs.templates',
    icon: cilClone,
    childItems: [
      {
        routeName: 'organizationId-template-sms',
        translationKey: 'general.header.navs.smsTemplate',
      },
      {
        routeName: 'organizationId-template-email',
        translationKey: 'general.header.navs.emailTemplate',
      },
    ],
  },
  {
    translationKey: 'general.header.navs.organization',
    icon: cilBuilding,
    childItems: [
      {
        routeName: 'organizationId-organization-details',
        translationKey: 'general.header.navs.organizationDetails',
      },
      {
        routeName: 'organizationId-organization-contacts',
        translationKey: 'general.header.navs.contacts',
      },
      {
        routeName: 'organizationId-organization-senderSms',
        translationKey: 'settings.menu.option.smsSenders',
      },
      {
        routeName: 'organizationId-organization-senderDomain',
        translationKey: 'settings.menu.option.domainSenders',
      },
      {
        routeName: 'organizationId-organization-suborganizations',
        translationKey: 'settings.menu.option.subOrganization',
      },
      {
        routeName: 'organizationId-organization-personalization',
        translationKey: 'settings.menu.option.whitelabel',
      },
      {
        routeName: 'organizationId-organization-userList',
        translationKey: 'settings.menu.option.userList',
      },
    ],
  },
])
</script>

<style></style>
